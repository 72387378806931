@import 'src/scss/_variables.scss';

.inactive-page-paper {
    width: 100%;

    .first-section{
        margin: 0 2rem 0 3rem;
    }

    .second-section{
        margin: 0 3rem 0 2rem;
    }

    .title {
        color: $textSecondary;
    }

    .title-margin {
        margin-top: 2rem;
    }

    .bottom-box {
        margin: 2rem 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        .left {
            width: 60%
        }

        .right {
            width: 40%
        }
    }

    .feedback-input {
        border-radius: 5;
        border: 1px solid rgb(207, 207, 207);
        font-family: 'Open Sans';
        background-color: $bgLight;
        margin-top: 1rem;
        &::before {
            border-bottom: none;
        }
        &::after {
            border-bottom: none;
        }

        &.subject{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: $textPrimary;
            font-size: 0.83rem;
            height: 1rem;
            padding: 18.5px 14px;
        }

        &.date{
            width: 129px;
            padding: 5px 1px 6px 9px
        }

    }

    .display-box {
        padding: 14px 14px;
        height: 150px;
        overflow: auto;
        background-color: $bgLight;
        border-radius: 2px;
    }

    .text-input {
        margin-top: 1rem;
        background-color: $bgLight;
    }
}